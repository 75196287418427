import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { feriadoStore, mainStore, userToolStore } from '@/utils/store-accessor';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => {
        return !!value;
    },
});
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.loadingAtualizaFeriados = false;
        this.anoSelecionado = 'Todos';
        this.anoSelecionadoSincronizar = null;
        this.dialogSincronizarFeriados = false;
        this.headers = [
            {
                text: 'Nome',
                sortable: true,
                value: 'nome',
                align: 'left',
            },
            {
                text: 'Data',
                sortable: true,
                value: 'data',
                align: 'left',
            },
            {
                text: 'Tipo',
                sortable: true,
                value: 'tipo',
                align: 'left',
            },
            {
                text: 'Ativo',
                sortable: true,
                value: 'ativo',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.loading = false;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Feriados) {
            return userToolStore.userToolsIndexed.Feriados[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Feriados) {
            return userToolStore.userToolsIndexed.Feriados[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Feriados) {
            return userToolStore.userToolsIndexed.Feriados[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Feriados) {
            return userToolStore.userToolsIndexed.Feriados[0].allow_delete;
        }
    }
    get feriados() {
        const todosFeriados = feriadoStore.feriados.sort((a, b) => {
            return a.data < b.data ? -1 : a.data > b.data ? 1 : 0;
        });
        if (this.anoSelecionado === 'Todos' || this.anoSelecionado === '') {
            return todosFeriados;
        }
        return todosFeriados.filter((feriado) => {
            const [ano, mes, dia] = feriado.data.split('-');
            if (ano === this.anoSelecionado) {
                return feriado;
            }
        });
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get anos() {
        const carregaAnos = [];
        feriadoStore.feriados.forEach((feriado) => {
            const [ano, mes, dia] = feriado.data.split('-');
            if (!carregaAnos.find((item) => item === ano)) {
                carregaAnos.push(ano);
            }
        });
        carregaAnos.sort();
        carregaAnos.unshift('Todos');
        return carregaAnos;
    }
    get anosSincronizar() {
        const retornaAnos = [...this.anos];
        retornaAnos.shift();
        if (retornaAnos.length > 0) {
            retornaAnos.push((parseInt(this.anos[this.anos.length - 1], 0) + 1).toString());
        }
        const anoAtual = new Date().getFullYear();
        const findAnoAtual = retornaAnos.find((ano) => Number(ano) === anoAtual);
        if (!findAnoAtual) {
            retornaAnos.push(anoAtual);
        }
        return retornaAnos;
    }
    async deleteFeriado(item) {
        this.$swal({
            title: 'Confirma a exclusão do feriado?',
            text: 'Após exclusão a sala não pode ser recuperada!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await feriadoStore.deleteFeriado(item.id);
                // @ts-ignore
                await feriadoStore.getFeriados();
                this.loading = false;
            }
        });
    }
    async handleAtualizarFeriadosDialog() {
        this.dialogSincronizarFeriados = true;
    }
    async handleAtualizarFeriados() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            return;
        }
        this.dialogSincronizarFeriados = false;
        this.loadingAtualizaFeriados = true;
        await feriadoStore.atualizaFeriados(this.anoSelecionadoSincronizar);
        await this.carregaTodosFeriados();
        this.loadingAtualizaFeriados = false;
    }
    async carregaTodosFeriados() {
        this.loading = true;
        await feriadoStore.getFeriados();
        this.loading = false;
    }
    routeEdit(item) {
        const itemName = item.name;
        // @ts-ignore
        const id = this.feriados.id.toString();
        this.$router.push({ name: 'main-feriados-edit', params: { id, itemName } });
    }
    async mounted() {
        this.anoSelecionado = new Date().getFullYear().toString();
        await this.carregaTodosFeriados();
    }
};
List = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
    })
], List);
export default List;
